<template>
    <div :class="CSSClasses">
        <ImageHero v-if="hero?.image" v-bind="hero" class="event-hero" />

        <template v-if="isConference">
            <div class="conference-head-wrapper">
                <EventSummary
                    :eventType="subtype"
                    :title="title"
                    :attendanceType="attendanceType"
                    :attributions="attributions"
                    :eventDate="eventDate"
                    :eventLocation="eventLocation"
                    :eventRSVP="eventRSVP"
                    class="conference-head"
                    :marketoCampaign="marketoCampaign"
                />
                <div id="registrationForm" class="hidden">
                    <Eyebrow :hasUnderline="true" :hasMargin="false" class="eyebrow">
                        {{
                            $t('Registration', 1, {
                                locale: pageLanguage,
                            })
                        }}
                    </Eyebrow>
                    <form :id="`mktoForm_${marketoCampaign.formId}`" v-if="marketoCampaign.enabled"></form>
                    <div id="confirmForm" style="display: none">
                        <strong>{{
                            $t('thank you', 1, {
                                locale: pageLanguage,
                            })
                        }}</strong>
                        <p>
                            {{
                                $t('You will receive an email confirming your registration.', 1, {
                                    locale: pageLanguage,
                                })
                            }}
                        </p>
                    </div>
                </div>
                <VideoBlock v-if="video" v-bind="video" class="conference-head" />
            </div>
            <StreamTierFeed :streamTiered="streamTiered" />
        </template>

        <Publication v-if="isEvent" :streamBlocks="streamContained" :tags="combinedTags">
            <template #head>
                <EventSummary
                    :eventType="subtype"
                    :title="title"
                    :attributions="attributions"
                    :attendanceType="attendanceType"
                    :eventDate="eventDate"
                    :eventLocation="eventLocation"
                    :eventRSVP="eventRSVP"
                    :marketoCampaign="marketoCampaign"
                />
            </template>
            <template #main-left>
                <AsideBlock v-if="affiliationContent.length" :hasPadding="false">
                    <AffiliationCard
                        v-for="item in affiliationContent"
                        :key="item.reference.path"
                        :heading="item.reference.title"
                        :dek="item.reference.dek || item.reference.about"
                        headingElement="h3"
                        :image="item.image"
                        :logoImage="item.logoImage"
                        :mobileImage="item.mobileImage"
                        :url="item.reference.path"
                        :eyebrow="$t(item.eyebrow)"
                        class="card"
                        :isDCPrimary="isDCPrimary"
                        :pageLanguage="pageLanguage"
                    />
                </AsideBlock>
                <AsideBlock v-if="supplementalLinks?.length" :heading="$t('additional links')">
                    <LinkList :links="supplementalLinks?.map((link) => link.link)" class="supplemental-links" />
                </AsideBlock>
            </template>

            <template #main-center>
                <div id="registrationForm" class="hidden">
                    <Eyebrow :hasUnderline="true" :hasMargin="false" class="eyebrow">
                        {{
                            $t('Registration', 1, {
                                locale: pageLanguage,
                            })
                        }}
                    </Eyebrow>
                    <form :id="`mktoForm_${marketoCampaign.formId}`" v-if="marketoCampaign.enabled"></form>
                    <div id="confirmForm" style="display: none">
                        <strong>{{
                            $t('thank you', 1, {
                                locale: pageLanguage,
                            })
                        }}</strong>
                        <p>
                            {{
                                $t('You will receive an email confirming your registration.', 1, {
                                    locale: pageLanguage,
                                })
                            }}
                        </p>
                    </div>
                </div>
                <VideoBlock v-if="video" v-bind="video" class="video-block" />
                <PublicationStreamBlocks :blocks="streamContained" />
            </template>
            <template #main-right>
                <AsideBlock v-if="contributors?.length" :heading="$t('event speakers')">
                    <ul class="event-speakers">
                        <li v-for="person in contributors" :key="person.id" class="speaker">
                            <PersonListItem :person="person" :isInline="true" size="33" />
                        </li>
                    </ul>
                </AsideBlock>
            </template>
        </Publication>

        <Tier background="grey" top-padding="extra" bottom-padding="extra" class="event-speakers-bottom">
            <div class="columns">
                <PersonFeedBlock
                    v-if="displaySpeakers && contributors.length"
                    :people="contributors"
                    size="100"
                    theme="light"
                >
                    <template #title>
                        <Typography variant="h4-display" as="div" class="contributor-feed-title">
                            {{ $t('event speakers') }}
                        </Typography>
                    </template>
                </PersonFeedBlock>
            </div>
        </Tier>

        <section v-if="isConference" class="disclaimer">
            <Typography variant="body-display-micro-italic">{{
                $t(
                    'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
                    1,
                    { locale: pageLanguage },
                )
            }}</Typography>
        </section>
    </div>
</template>

<script setup>
const { getCenterTagsBySyndication } = useCenters();
const { pageLanguage } = usePageDataStore();

const props = defineProps({
    attendanceType: {
        type: String,
    },
    dek: {
        type: Object,
    },
    contributors: {
        type: Object,
    },
    displaySpeakers: {
        type: Boolean,
    },
    eventDate: {
        type: Object,
    },
    eventLocation: {
        type: String,
    },
    eventRSVP: {
        type: Object,
    },
    subtype: {
        type: String,
    },
    hero: {
        type: Object,
    },
    primaryContact: {
        type: Object,
    },
    supplementalLinks: {
        type: Object,
    },
    streamContained: {
        type: Object,
    },
    streamTiered: {
        type: Object,
    },
    tags: {
        type: Array,
    },
    title: {
        type: String,
    },
    syndications: {
        type: Object,
    },
    video: {
        type: Object,
    },
    locale: {
        type: String,
        default: '',
    },
    marketoCampaign: {
        type: Object,
    },
});

const CSSClasses = computed(() => {
    return ['event-page', `--${props.subtype}`];
});

const isEvent = computed(() => {
    return props.subtype === 'event';
});

const isConference = computed(() => {
    return props.subtype === 'conference';
});

const attributions = useAttribution(props.syndications);

let isDCPrimary = true;
if (attributions.attributionItems.length) {
    isDCPrimary = false;
}

const affiliationContent = getAffiliationContent(props.syndications);
const collectionContent = getCollectionContent(props.syndications);
const combinedTags = getCenterTagsBySyndication(props.syndications, props.tags);

const headerAttributesStore = useHeaderAttributesStore();
const GTMType = props.subtype;
const { GTMCenter, GTMAuthors, GTMPrograms, GTMProjects, GTMRegions, GTMTopics } = useGTMContent(
    'syndication',
    props,
    affiliationContent,
    combinedTags,
    collectionContent,
);
const GTMLang = props.locale;

const loadMarketoForm = () => {
    if (props.marketoCampaign && props.marketoCampaign.formId && props.marketoCampaign.enabled) {
        MktoForms2.loadForm(
            '//Pages.carnegieendowment.org',
            '813-XYU-422',
            props.marketoCampaign.formId,
            function (form) {},
        );
        MktoForms2.whenReady(function (form) {
            //Add an onSubmit to add hidden field values
            //Add an onSuccess handler
            form.onSuccess(function (values, followUpUrl) {
                //get the form's jQuery element and hide it
                form.getFormElem().hide();
                document.getElementById('confirmForm').style.display = 'block';
                //return false to prevent the submission handler from taking the lead to the follow up url.
                return false;
            });
        });
    }
};

onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter,
        Doctype: GTMType,
        Expert: GTMAuthors,
        'Taxonomy Tags Program': GTMPrograms,
        'Taxonomy Special Project': GTMProjects,
        'Taxonomy Tags Regions': GTMRegions,
        'Taxonomy Tags Topics': GTMTopics,
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'white' });
    //- unique page code
    npconference22();
    changeAgendaDate();
    loadMarketoForm();
});

function videoChange(idName, addEmbed) {
    const selectorUse = '#' + idName + ' .videoEmbed iframe';
    document.querySelector(selectorUse).setAttribute('src', `https://www.youtube.com/embed/${addEmbed}?enablejsapi=1`);
    const elList = document.querySelectorAll('.agenda .content');
    elList.forEach((el) => {
        el.classList.remove('active');
    });
    const idUse = '#' + idName;
    document.querySelector(idUse).classList.add('active');
}

function videoChangeMultiDay(idName, addEmbed) {
    const selectorUse = '#' + idName + ' .videoEmbed iframe';
    document.querySelector(selectorUse).setAttribute('src', `https://www.youtube.com/embed/${addEmbed}?enablejsapi=1`);
    const elList = document.querySelectorAll('.agenda .active-date .content');
    elList.forEach((el) => {
        el.classList.remove('active');
    });
    const idUse = '#' + idName;
    document.querySelector(idUse).classList.add('active');
}

function changeAgendaDate() {
    const GTSDayNov29 = document.getElementById('dayNov29Button');
    const GTSDayNov30 = document.getElementById('dayNov30Button');
    const GTSDayDec1 = document.getElementById('dayDec1Button');
    const GTSDayDec142021 = document.getElementById('dayDec14Button');
    const GTSDayDec152021 = document.getElementById('dayDec15Button');
    const GTSDayDec162021 = document.getElementById('dayDec16Button');
    if (GTSDayNov29) {
        document.querySelector('#dayNov29Button').onclick = function (e) {
            document.getElementById('dayNov29Content').classList.add('active-date');
            document.getElementById('dayNov30Content').classList.remove('active-date');
            document.getElementById('dayDec1Content').classList.remove('active-date');
            const elList = document.querySelectorAll('.agenda .button-big.teal');
            elList.forEach((el) => {
                el.classList.remove('active');
            });
            e.target.classList.add('active');
        };
    }
    if (GTSDayNov30) {
        document.querySelector('#dayNov30Button').onclick = function (e) {
            document.getElementById('dayNov29Content').classList.remove('active-date');
            document.getElementById('dayNov30Content').classList.add('active-date');
            document.getElementById('dayDec1Content').classList.remove('active-date');
            const elList = document.querySelectorAll('.agenda .button-big.teal');
            elList.forEach((el) => {
                el.classList.remove('active');
            });
            e.target.classList.add('active');
        };
    }
    if (GTSDayDec1) {
        document.querySelector('#dayDec1Button').onclick = function (e) {
            document.getElementById('dayNov29Content').classList.remove('active-date');
            document.getElementById('dayNov30Content').classList.remove('active-date');
            document.getElementById('dayDec1Content').classList.add('active-date');
            const elList = document.querySelectorAll('.agenda .button-big.teal');
            elList.forEach((el) => {
                el.classList.remove('active');
            });
            e.target.classList.add('active');
        };
    }
    if (GTSDayDec142021) {
        document.querySelector('#dayDec14Button').onclick = function (e) {
            document.getElementById('dayDec15Content').classList.remove('active-date');
            document.getElementById('dayDec16Content').classList.remove('active-date');
            document.getElementById('dayDec14Content').classList.add('active-date');
            const elList = document.querySelectorAll('.agenda .button-big.teal');
            elList.forEach((el) => {
                el.classList.remove('active');
            });
            e.target.classList.add('active');
        };
    }
    if (GTSDayDec152021) {
        document.querySelector('#dayDec15Button').onclick = function (e) {
            document.getElementById('dayDec14Content').classList.remove('active-date');
            document.getElementById('dayDec16Content').classList.remove('active-date');
            document.getElementById('dayDec15Content').classList.add('active-date');
            const elList = document.querySelectorAll('.agenda .button-big.teal');
            elList.forEach((el) => {
                el.classList.remove('active');
            });
            e.target.classList.add('active');
        };
    }
    if (GTSDayDec162021) {
        document.querySelector('#dayDec16Button').onclick = function (e) {
            document.getElementById('dayDec14Content').classList.remove('active-date');
            document.getElementById('dayDec15Content').classList.remove('active-date');
            document.getElementById('dayDec16Content').classList.add('active-date');
            const elList = document.querySelectorAll('.agenda .button-big.teal');
            elList.forEach((el) => {
                el.classList.remove('active');
            });
            e.target.classList.add('active');
        };
    }
}

//- unique page code
function npconference22() {
    if (document.getElementById('npc2022EventPage')) {
        const selectElement = document.querySelector('[name="NPC22Panels"]');
        const videos = {
            grossi: 'aXJ3hjraxOc',
            ukraine: 'e8RBaT7mxDs',
            tick: 'neGjxVx5DIY',
            zlauvinen: '4lM9TgkU170',
            arms: '4lM9TgkU170',
            delpech: 'n1m1bnk-YvU?',
            keul: '70j6kbaGCr0',
            npr: 'Fr6tr3-rhZw',
            unpacking: 'wtmMgqofQos',
            humanitarian: 'gp9wSv6jH4k',
            intro: 'zv8kOHb0V40',
            deterrence: '',
            resolution: '4kUyjDocEig',
            poles: 'lJ6dwNO_Pds',
            educating: 'ZdOJ3_gm5RU',
            stability: '43iBmV8_oeM',
            delivery: 'FFvziIkYD6I',
            diversity: 'D9k575nOBF8',
            armsControl: 'jTEgSvjAjFE',
            australia: 'Qp6jPNayYQ8',
            memoriam: '_YqKc0vAPTQ',
        };

        selectElement.addEventListener('change', (event) => {
            const embedList = document.querySelectorAll('.videoEmbed iframe');
            embedList.forEach((el) => {
                el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            });
            videoChange(`${event.target.value}Panel`, videos[event.target.value]);
        });
    } else if (document.getElementById('npc2021EventPage')) {
        const selectElement = document.querySelector('[name="NPC21Panels"]');
        const videos = {
            soreide: 'qcwPFIe1wx4',
            nuclearRiskReduction: 'ARmU39lqkUE',
            ryabkov: 'yrj082o4tGM',
            equity: 'Ihi2T_8TaP8',
            delpech: 'fY5yIqWzXlM',
            kahl: '2NSELljDFNk',
            asia: 'K411hLY_kGg',
            memoriam: 'YcFPr5Hev3U',
            armsControl: 'Qmy5sPDng5E',
        };

        selectElement.addEventListener('change', (event) => {
            const embedList = document.querySelectorAll('.videoEmbed iframe');
            embedList.forEach((el) => {
                el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            });
            videoChange(`${event.target.value}Panel`, videos[event.target.value]);
        });
    } else if (document.getElementById('gtc2022EventPage')) {
        const selectElementDay1 = document.querySelector('[name="gtc22PanelsNov29"]');
        const selectElementDay2 = document.querySelector('[name="gtc22PanelsNov30"]');
        const selectElementDay3 = document.querySelector('[name="gtc22PanelsDec1"]');
        const videos = {
            welcomeRemarks: 'qCW7Z_curf8',
            inauguralAddress: 'UTEm4t4EM28',
            inauguralConversation: 'ikWdSltDZUE',
            videoMessage: 'qHqY_qWvOmA',
            firstKeynote: '0y7fbcwJuSo',
            IndiaDigitalWay: '0DuoEIS8OxE',
            videoMessageIntro: '7rUauygNQGs',
            digitalId: 'DyGc7V2j0WQ',
            secondKeynote: 'A_MnxmUsYkM',
            digitalHealth: 'xyy_g8dfvSA',
            thirdKeynote: 'QnrXezUEnZg',
            firstConversation: 'I_FYtxyB9b0',
            aConnectedWorld: '_24caIuSGXs',
            firstCurtainRiser: 'DQYGwXE_5Jg',
            indiaStack: 'sfInBUOgIcQ',
            theWorld: 'zgkN-oSpq0E',
            firstVideoMessage: 'ROwf6si0Oyg',
            digitalInfrastructure: 'xus_J5yOU3c',
            firstMinisterialAddress: 'mKyufLF7AN8',
            cyberResilience: 'mRN98rq-QFk',
            firstMinisterialAddressLekhi: 'gzWE98XzC00',
            inSpace: 'Dl6IEeJ09Cg',
            secondConversation: 'CpLMfHLZBeI',
            firstTalk: 'Hhq80YQI6os',
            startUp20: 'pPMmbq7v5Io',
            secondTalk: 'hPryvNbjhI8',
            thirdConversation: '5n78B-vQuyA',
            fourthKeynote: 'pffiIiCBLDA',
            techAndTrade: 'GO4O4O7P5BQ',
            publicInfrastructure: 'ZYRn1t3KlIc',
            fourthConversation: 'g2XWnwcNwmk',
            thirdTalk: 'mabF67_Xx08',
            bridgital: 'TGuz2yuTxyE',
            fourthTalk: 'gyNcBVnF_sY',
            AI: 'zh268N3ipT8',
            bookDiscussion: 'HGh2jVeAIN0',
            fifthConversation: 'EE2lYAZH3Pw',
            secondMinisterialAddress: 'VFxZDH3TiXI',
            thirdMinisterialAddress: 'ik376roo0Dc',
            saveTheData: '6ISiIocgJ4Y',
            fourthMinisterialAddress: 'WewHpEkhUDs',
            warInUkraine: 'e2U0hpF-q8o',
            netZero: 'RVKWUZZmcxg',
            sixthConversation: 'nfFI9YjxuMY',
            localContent: 'Jv8RQ90_EuY',
            bioSafety: 'yUzPbipLl6U',
            fifthMinisterialAddress: 'nigY3uZ8fxg',
            fifthKeynote: 'bsDQrKr4JKg',
            openNetwork: 'gfZrstXM3Lk',
            sixthMinisterialAddress: 'D4NDQn4zNN4',
            sixthKeynote: 'I6Vn0s6RLf8',
            seventhConversation: 'CmguQQk60Bo',
            bookLaunch: 'KJqE57ZDR5s',
            eighthConversation: 'KJqE57ZDR5s',
            panelDiscussion: 'zDfQ4ur--PQ',
        };

        selectElementDay1.addEventListener('change', (event) => {
            const embedList = document.querySelectorAll('.videoEmbed iframe');
            embedList.forEach((el) => {
                el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            });
            videoChangeMultiDay(`${event.target.value}`, videos[event.target.value]);
        });

        selectElementDay2.addEventListener('change', (event) => {
            const embedList = document.querySelectorAll('.videoEmbed iframe');
            embedList.forEach((el) => {
                el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            });
            videoChangeMultiDay(`${event.target.value}`, videos[event.target.value]);
        });

        selectElementDay3.addEventListener('change', (event) => {
            const embedList = document.querySelectorAll('.videoEmbed iframe');
            embedList.forEach((el) => {
                el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            });
            videoChangeMultiDay(`${event.target.value}`, videos[event.target.value]);
        });
    } else if (document.getElementById('gtc2021EventPage')) {
        const selectElementDay1 = document.querySelector('[name="gtc21PanelsDec14"]');
        const selectElementDay2 = document.querySelector('[name="gtc21PanelsDec15"]');
        const selectElementDay3 = document.querySelector('[name="gtc21PanelsDec16"]');
        const videos = {
            johnson: 'oZGZch0zQ2Q',
            geopoliticsTech: 'cdoV6DFlIsI',
            truss: 'cxHhMYuDOtA',
            cooperationQuad: '-NVzbWiGTrs',
            buildingResilience: 'P2OwrBR5TZM',
            investingTech: 'lCwbYPSZLlc',
            virtuesQuad: 'TtqHfI8-OZo',
            securingVaccine: 'Z2zTubF5SaQ',
            fragmentation: 'mgQDYslKeCg',
            edTech: 'K5YrWrkAgHE',
            strategicUPI: 'AWA5zEqeWIM',
            upiHighway: 'AWA5zEqeWIM',
            cryptocurrencies: 'a5BcEalftNA',
            futureFintech: '0zQ4hODgsEs',
            innovationBillion: 'v0_m18tkk0c',
            futureDigitalTransformation: 'y8bwUYls0tI',
            opening: 'B9ayrjZh-hM',
            partnerships: 'UpmadjtLCFk',
            uhi: '3dRV_cxgQlI',
            cyberGovernance: 'XFYCA1XiRwQ',
            cloudGovernance: 'gsGzFYmU5FU',
            skilling: 'H2GQalreWok',
            aukus: 'v5shGjN8fEk',
            karnataka: 'pK80cGWavIk',
            pandemics: 'GLGkGLWBGJo',
            tomorrow: 'vdNKc3cHB6E',
            indiaBrazil: 'kb0yHJOdC8g',
            indiaSlovenia: 'MRyS1M1MYU0',
            rai: '-C-lCliUWGE',
            trillionEconomy: '36gkzVsBekY',
            supplyChains: 'CDEWBrT3v4o',
            futureTech: 'oNEDZ9ChFHc',
            leveragingTech: '-J2W5ScLQ1g',
            france: 'DDUXpd-t4d4',
            vietnam: 'hThr7hYwecs',
            muraleedharan: 'wXrkwuJO91U',
            grand: 'hCLfBQEZDRY',
            digitalAsia: 'pGLS9Fr84So',
            sustainability: 'HOIPQRVTydc',
            cloudSustainability: 'Xvh90Fntboo',
            futureEncryption: 'K9TRJI7Z93s',
            world: 's5FmDQplIII',
            buildingAI: 'qun9lFw3Scs',
            gokhale: '5Yrj8iDWTEI',
            digitalConnectivity: 'P452tscibHY',
            dataProtection: '-ObOuoQ5JSs',
            greenTech: 'lTIm-PMS1ak',
            techInnovationSustainable: 'Y7KJiy7rfMY',
            ecommerce: '40rZ2j5gN0s',
            openEcoSingh: 'CbRESzQzKAE',
            openEcoChugh: 'vncEKOtWOb0',
            openEcoAhluwalia: 'RgzXnnwNPGE',
            frontiers: 'cLgcLIYUBbA',
        };

        selectElementDay1.addEventListener('change', (event) => {
            const embedList = document.querySelectorAll('.videoEmbed iframe');
            embedList.forEach((el) => {
                el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            });
            videoChangeMultiDay(`${event.target.value}`, videos[event.target.value]);
        });

        selectElementDay2.addEventListener('change', (event) => {
            const embedList = document.querySelectorAll('.videoEmbed iframe');
            embedList.forEach((el) => {
                el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            });
            videoChangeMultiDay(`${event.target.value}`, videos[event.target.value]);
        });

        selectElementDay3.addEventListener('change', (event) => {
            const embedList = document.querySelectorAll('.videoEmbed iframe');
            embedList.forEach((el) => {
                el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            });
            videoChangeMultiDay(`${event.target.value}`, videos[event.target.value]);
        });
    }
}
</script>

<style lang="scss" scoped>
.event-hero {
    height: 30rem;
    overflow: hidden;
}

.conference-head-wrapper {
    @include content-section;
    @include padding-tb(vertical-space(2));
    @include grid;

    .conference-head {
        @include content-area;

        @include media-query(not-phone) {
            grid-column: 4 / span 6;
        }
        @include media-query(mobile) {
            grid-column: 2 / span 10;
        }
    }
}

.event-speakers {
    .speaker {
        margin-top: vertical-space(0.5);
    }
}

.contributor-feed-title {
    text-align: center;
    margin-bottom: vertical-space(1);
    text-transform: capitalize;
}

.block {
    margin: vertical-space(1);
}
.video-block {
    margin-bottom: 3rem;
}

@include media-query(mobile) {
    :deep(.event-speakers-bottom .person-details .rich-text) {
        display: block;
    }
    :deep(.main-right) {display: none;}
}

//supporting legacy

:deep(.video-embed),
:deep(.videoEmbed) {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: 30px;

    .embed-floated & {
        margin-bottom: 0;
    }

    iframe,
    .video-embed iframe,
    .video-embed object,
    .video-embed embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

:deep(.gutter-top) {
    margin-top: 1em;
}

:deep(.panel-description) {
    font-weight: 100;
    margin-bottom: 1em;

    .reveal-this {
        display: inline;
    }
}

:deep(.program-highlights) {
    ul.clean-list li span {
        display: block;
    }

    ul.clean-list li {
        margin-bottom: 1em;
    }
}

//- unique styles for conferences
$npc22-teal: #00ffd9;
$medium-gray: #e6e7e8;
$carnegie-blue: #003a63;
$teal: #00a79d;
$copper: #89684f;
$white: #ffffff;
$black: #000000;
$gutter: 30px;

:deep(#npc2022EventPage),
:deep(#npc2021EventPage),
:deep(#gtc2022EventPage),
:deep(#gtc2021EventPage) {
    .hidden {
        display: none !important;
    }

    .foreground {
        max-width: 1000px;
        margin: 0 auto;
    }

    .cols {
        clear: both;

        .col {
            float: left;

            &.col-50:nth-child(2n + 1) {
                clear: both;
            }

            &.col-50 {
                width: 50%;
            }
        }
    }

    .gutters {
        margin-left: calc($gutter / 2);
        margin-right: calc($gutter / 2);
    }

    .center {
        text-align: center;
    }

    .section-title {
        display: inline-block;
        margin: 60px 0 60px 0;
        width: auto;

        &.bordered-box--header {
            border: 1px solid #a28556;
            color: #a28556;
            font-family: 'Frutiger Neue LT W06 Bold', 'Arial Black', Gadget, sans-serif;
            padding: 10px;
            text-transform: uppercase;
        }
    }

    select {
        border: 1px solid #ccc;
        background: white;
    }

    .gutter-bottom__double {
        margin-bottom: 60px;
    }

    #aboutTab {
        display: none;
    }

    h4.teal,
    h5.teal {
        color: $teal;
    }

    .button-big.npc-teal {
        box-shadow: none;
        background-color: $npc22-teal;
        color: $carnegie-blue;
        padding: 0.6rem 1rem 0.7rem;
    }

    .list-across-divided.npc-teal {
        margin-inline-end: 0;
        text-transform: uppercase;

        li {
            border-inline-end: 2px solid $carnegie-blue;
            margin-inline-end: 7.5px;
            font-size: 0.8rem;
            line-height: 1rem;
        }

        li:last-child {
            border: none;
            margin-inline-end: 0;
        }

        a {
            color: $npc22-teal;
            font-size: 0.9rem;
            line-height: 1rem;
        }
    }

    .agenda {
        background-color: $medium-gray;
        display: flex;
        padding-bottom: $gutter;

        .gutters {
            margin-bottom: calc($gutter / 2);

            &.gutter-bottom__double {
                margin-bottom: $gutter;
            }
        }

        h4 {
            margin-bottom: $gutter;
        }

        .list-across-spaced .button-big {
            cursor: pointer;
            opacity: 0.7;

            &.active {
                opacity: 1;
            }
        }

        .content {
            display: none;
            padding-bottom: $gutter;

            &.active,
            &.active-date {
                display: block;
            }
        }

        .day-title {
            letter-spacing: 3px;
            padding-bottom: $gutter;
            text-transform: uppercase;

            span {
                display: block;
                text-transform: none;
            }
        }

        form {
            fieldset {
                border: none;
            }

            label {
                text-transform: uppercase;
            }

            select {
                border: 1px solid $white;
                border-radius: 5px;
                display: block;
                padding: 5px;
                margin-top: 1rem;
                font-size: 1.2rem;
                text-transform: uppercase;
            }
        }

        .cols,
        .panel {
            // margin-left: $gutter;
            h5.teal {
                color: $teal;
                font-size: 2rem;
                margin-bottom: $gutter;
            }

            p.description {
                font-size: 1.5rem;
                font-style: italic;
                max-width: 100%;
            }

            h6 {
                color: $copper;
                margin-top: $gutter;
                margin-bottom: 10px;
                font-size: 1.9rem;
                font-weight: bold;
            }

            h4.speaker-name {
                color: $black;
                font-size: 2rem;
                font-weight: bold;
                margin-bottom: 10px;

                a {
                    color: $black;
                }
            }

            .component {
                line-height: 1.1;
                font-size: 1.3rem;
                color: #6e6e71;
                margin-top: calc($gutter / 6);
            }

            .button-big.teal {
                background-color: $teal;
            }

            .videoEmbed {
                margin-bottom: $gutter;
            }
        }

        .container-connector {
            display: none;
        }
    }

    .young-professionals,
    .knowledge-transfer {
        background-color: $carnegie-blue;
        display: flex;

        .section-title.bordered-box--header {
            border: 1px solid $white;
            color: $white;
        }

        .section-content {
            color: $white;

            strong {
                font-size: 2rem;
                display: block;
                margin-top: calc($gutter / 1.5);

                &.first {
                    margin-top: 0;
                }
            }

            a {
                color: $teal;
            }

            a.button-big {
                color: $white;
            }

            h4.white a,
            .white {
                color: $white;
            }

            .center {
                max-width: 100%;
            }
        }

        .button-big.red {
            margin-top: $gutter;
        }

        li,
        p {
            font-size: 2rem;
        }

        .gutter-top {
            margin-top: $gutter;
        }

        .container-connector .connector {
            display: none;
        }

        .reveal-this {
            font-size: 0.8em;
            line-height: 1.3em;
            margin: 1em 0 2em 0;
        }

        .logo {
            height: 100px;
            width: 400px;
            background-size: contain;
            background-repeat: no-repeat;
            float: left;
            margin-inline-end: calc($gutter * 1.5);

            &.center.logos {
                display: inline-block;
                margin: 0 auto;
            }

            &.poni {
                background-image: url(https://ceipimg.s3.amazonaws.com/email/cosponsor-images/PONI-logo-dark.png);
                display: inline-block;
                float: none;
                margin: 0 auto;
                width: 200px;
            }

            &.wcaps {
                background-image: url(https://ceipimg.s3.amazonaws.com/email/cosponsor-images/WCAPS-logo-original-opt1-white.png);
            }

            &.cns {
                background-image: url(https://ceipimg.s3.amazonaws.com/email/cosponsor-images/2022-CNS_logo.png);
            }
        }
    }

    .award {
        background-color: $white;

        .section-title.bordered-box--header {
            border: 1px solid $black;
            color: $black;
        }

        p {
            max-width: 100%;
        }

        .container-connector .connector {
            display: none;
        }
    }

    @include media-query(mobile) {
        .agenda {
            form select {
                font-size: 0.5rem;
            }
            .day-title {
                color: $teal;
                font-size: 1.5rem;
            }
            .col-50 {
                margin-top: $gutter;
            }
        }
        .young-professionals .logo {
            width: 300px;
        }
    }
}
.disclaimer {
    margin: 4.8rem 0;
    span {
        margin: 0 auto;
        width: fit-content;
        display: block;
    }
}
#registrationForm {
    display: block;
    margin-bottom: 2em;
    &.hidden {
        display: none;
        margin-bottom: 0;
    }
}

.event-page.--conference {
    #registrationForm {grid-column: 5;}
    @include media-query(mobile) {
        :deep(.controls .share-buttons) { display: block;}
    }
}

:deep(.mktoForm .mktoButtonWrap.mktoRound .mktoButton) {
    border: none;
    color: rgb(var(--color-rgb-blanc-default));
    background-color: rgb(var(--color-rgb-teal-default));
    background-image: none;
    border-radius: 5px;
    text-decoration: none;
}
</style>
